@tailwind base;
@tailwind components;
@tailwind utilities;

.input,
.textarea,
.select,
.file-input {
    @apply border-gray-400 w-full
}

.input:focus, .input:focus-within, .textarea:focus, .textarea:focus-within, .select:focus, .select:focus-within, .file-input:focus, .file-input:focus-within {
    @apply outline-none border-primary border-2
}


a {
    @apply text-primary
}

.striped>div:nth-child(even) {
    @apply bg-gray-50
}

.sidebar {
    @apply flex flex-col gap-5
}

/* .sidebar>div {
    @apply flex flex-col gap-5
} */

.sidebar>a, .sidebar>div>a {
    @apply btn btn-primary lg:btn-lg text-sm lg:text-2xl
}

.sidebar>a.active {
    @apply btn bg-gray-400 border-0 text-white lg:btn-lg text-sm lg:text-2xl
}

.rmsc .dropdown-container {
    @apply w-full !border-0 !rounded-lg
}

.lg-react-element a {
    @apply inline-block mr-4
}

.error {
    @apply text-error font-semibold text-sm
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.drawer-toggle:checked~.drawer-side>.drawer-overlay {
    @apply !bg-black/80
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.dropdown-heading {
    @apply !h-full
}

.dropdown-heading-value .gray {
    @apply !text-black
}